// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$pagination-color: '#19384d';
$pagination-active-color:  white;
$pagination-active-bg:   '#19384d';